import useConfig from "./useConfig";
import {Answer} from "../types/Answer";
import queryString, {ParsedQuery} from "query-string";

function defined(value?: any): boolean {
  return value !== undefined;
}

export default function useSaveSubmission(organizationId: string, submissionId?: string, queryParams?: ParsedQuery) {
  const { apiUrl } = useConfig();
  const search = queryParams ? queryString.stringify(queryParams) : undefined;
  const url = [[apiUrl, 'covid-screening', organizationId, submissionId].filter(defined).join('/'), search].filter(defined).join('?')
  return async (answer: Answer<any>) => {
    const result = await fetch(url, {
      method: submissionId ? 'PUT' : 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(answer)
    });

    if (!result.ok) {
      console.warn('Failed to save submission', result);
    }

    return result;
  };
}
