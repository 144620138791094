import React, { useCallback, useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format, isValid, parse } from 'date-fns';
import { isDate } from 'lodash';
import { DateAnswer } from '../../types/Answer';
import { DateQuestion } from '../../types/Question';

type DateInputProps = DateQuestion["props"] & {
  answer?: DateAnswer;
  onChange?: (newAnswer: DateAnswer) => void;
}

const DateInput = ({ label, answer, onChange}: DateInputProps) => {

  const [date, setDate] = useState<Date | null>(answer ? parse(answer, "MM/dd/yyyy", new Date()) : null);


  useEffect(() => {
    if (date === null && answer) {
      answer && setDate(parse(answer, "MM/dd/yyyy", new Date()));
    }
  }, [answer, date]);

  const handleChange = useCallback((newValue: Date | null) => {
    setDate(newValue);
    if (isValid(newValue) && isDate(newValue)) {
      onChange?.(format(newValue, 'MM/dd/yyyy'));
    }
  }, [ onChange ]);

  return <Box my={2}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        inputFormat="MM/dd/yyyy"
        value={date}
        onChange={(newValue) => handleChange(newValue)}
        renderInput={(params: any) => <TextField variant="filled" fullWidth={true} {...params} />}
       />
    </LocalizationProvider>
  </Box>;
};

export default DateInput;
