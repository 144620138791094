import React, {ReactNode} from 'react';
import TextAnswer from "./TextAnswer";
import {YesNoQuestionChildren} from "../Form";
import isBoolean from "lodash/isBoolean";
import { YesNoQuestion } from '../../types/Question';
import { YesNoAnswer } from '../../types/Answer';

type Props = YesNoQuestion["props"] & YesNoQuestionChildren<ReactNode> & {
  answer?: YesNoAnswer;
}

const YesNoTextAnswer = ({ label, answer, child } : Props) => {
  const value = isBoolean(answer) ? answer? "Yes" : "No"
    : isBoolean(answer?.value) ? answer?.value ? "Yes" : "No" : "";
  const booleanValue = isBoolean(answer) ? answer
    : isBoolean(answer?.value) ? answer?.value : undefined;
  return (
    <div>
      <TextAnswer question={label} answer={value}/>
      {(booleanValue === true || booleanValue === false) && child}
    </div>
  );
};

export default YesNoTextAnswer;
