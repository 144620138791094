import {ParsedQuery} from "query-string";

const keyExpansions: { [key: string]: string } = {
  c: 'category',
  r: 'parent',
};

const valueExpansions: { [key: string]: string } = {
  p: 'patient',
  c: 'caretaker',
  s: 'staff',
  o: 'other',
};

function expandKey(key: string): string {
  return keyExpansions[key] ?? key;
}

function expandValue(value: string): string {
  return valueExpansions[value] ?? value;
}

export default function expandQueryParams(query: ParsedQuery): ParsedQuery {
  return Object.fromEntries(
    Object.entries(query).map(([key, value]) =>
      [expandKey(key), value == null ? null : Array.isArray(value) ? value.map(expandValue) : expandValue(value)]
    )
  );
}
