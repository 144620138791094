import React, { FC } from 'react';
import { Divider, Stack, Typography, useTheme } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface NotFoundProps {}

const NotFound: FC<NotFoundProps> = () => {
  const theme = useTheme();

  return (
    <Stack alignItems="center" justifyContent="center" style={{ minHeight: '67vh' }}>
      <Stack direction="column">
        <ErrorOutlineIcon />
        <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2), width: theme.spacing(8) }} />
        <Typography variant="h5" gutterBottom>
          Not Found
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          The page you are looking for does not exist.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default NotFound;
