import React, { cloneElement, ReactNode } from 'react';
import YesNoInput from './questions/YesNoInput';
import TextInput from './questions/TextInput';
import DateInput from './questions/DateInput';
import { isValid, parse } from 'date-fns';
import isEqual from 'lodash/isEqual';
import isBoolean from 'lodash/isBoolean';
import DateTextAnswer from './answers/DateText';
import TextInputAnswer from './answers/TextInputAnswer';
import YesNoTextAnswer from './answers/YesNoTextAnswer';
import { DateQuestion, ImagesQuestion, ObjectQuestion, Question, TextQuestion, YesNoQuestion } from '../types/Question';
import { Answer, DateAnswer, ImagesAnswer, ObjectAnswer, TextAnswer, YesNoAnswer, YesNoAnswerWithChildren } from '../types/Answer';
import ImagesInput from './questions/ImagesInput';

export type ObjectQuestionChildren<T, Q extends ObjectQuestion> = { [key in keyof Q['props']['fields']]: T }
export type YesNoQuestionChildren<T> = { child: T | null }

export type Tags = {
  [key: string]: string;
}

type FormRendererSpec<T> = {
  renderObject<Q extends ObjectQuestion>(children: ObjectQuestionChildren<T, Q>, tags: Tags, question: Q['props'], answer?: Answer<Q>, onChange?: (newAnswer: Answer<Q>) => void): T;
  renderYesNo<Q extends YesNoQuestion>(children: YesNoQuestionChildren<T>, tags: Tags, question: Q['props'], answer?: Answer<Q>, onChange?: (newAnswer: Answer<Q>) => void): T;
  renderText<Q extends TextQuestion>(tags: Tags, question: Q['props'], answer?: Answer<Q>, onChange?: (newAnswer: Answer<Q>) => void): T;
  renderDate<Q extends DateQuestion>(tags: Tags, question: Q['props'], answer?: Answer<Q>, onChange?: (newAnswer: Answer<Q>) => void): T;
  renderImages<Q extends ImagesQuestion>(tags: Tags, question: Q['props'], answer?: Answer<Q>, onChange?: (newAnswer: Answer<Q>) => void): T;
}

type FormRenderer<T, Q extends Question> = (tags: Tags, question?: Q, answer?: Answer<Q>, onChange?: (newAnswer: Answer<Q>) => void) => T;
type FormRendererProvider<T> = (spec: FormRendererSpec<T>) => FormRenderer<T, Question>;

const provideFormRenderer: FormRendererProvider<ReactNode> = (spec) => (tags, question, answer, onChange) => {
  if (question) {
    switch (question.type) {
      case 'Object': {
        const children = Object.entries(question.props.fields).reduce((res, curr) => {
          const [name, child] = curr;
          return {
            ...res,
            [name]: provideFormRenderer(spec)(
              tags,
              child,
              answer ? (answer as ObjectAnswer<ObjectQuestion>)[name] : undefined,
              (newAnswer) => onChange?.(answer ? { ...(answer as object), [name]: newAnswer } : { [name]: newAnswer }),
            ),
          };
        }, {});
        return spec.renderObject(children, tags, question.props, answer as ObjectAnswer<ObjectQuestion>);
      }
      case 'Text':
        return spec.renderText(tags, question['props'], answer as TextAnswer, onChange);
      case "YesNo":
        const children = {
          child: question["props"].ifYes && (answer === true || (answer as YesNoAnswerWithChildren)?.value === true) ?
            provideFormRenderer(spec)(
              tags,
              question["props"].ifYes,
              answer ? (answer as YesNoAnswerWithChildren).ifYes : undefined,
              (newAnswer) => {
                onChange?.(answer ? {...(answer as object), ifYes: newAnswer} : {ifYes: newAnswer})
              }
            ) : question["props"].ifNo && (answer === false || (answer as YesNoAnswerWithChildren)?.value === false) ?
              provideFormRenderer(spec)(
                tags,
                question["props"].ifNo,
                answer ? (answer as YesNoAnswerWithChildren).ifNo : undefined,
                (newAnswer) => onChange?.(answer ? {
                  ...(answer as object),
                  ifNo: newAnswer
                } : {ifNo: newAnswer})
              ) : null,
        }
        const onYesNoChange = question["props"].ifYes || question["props"].ifNo
          ? (newAnswer: YesNoAnswer) => onChange?.(answer ? { ...(answer as object), value: newAnswer } : { value: newAnswer })
          : onChange;
        return spec.renderYesNo(children, tags, question["props"], answer as YesNoAnswer, onYesNoChange);
      case 'Date':
        return spec.renderDate(tags, question['props'], answer as DateAnswer, onChange);
      case 'Images':
        return spec.renderImages(tags, question['props'], answer as ImagesAnswer, onChange);
    }
  }
  return undefined;
};

export const renderEditableFormUI = provideFormRenderer({
  renderDate(tags: Tags, question: DateQuestion['props'], answer: Answer<DateQuestion> | undefined, onChange): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <DateInput {...question} answer={answer} onChange={onChange as (newAnswer: DateAnswer) => void} /> : null;
  },
  renderText(tags: Tags, question: TextQuestion['props'], answer: Answer<TextQuestion> | undefined, onChange): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <TextInput {...question} answer={answer} onChange={onChange as (newAnswer: TextAnswer) => void} /> : null;
  },
  renderYesNo(children, tags: Tags, question: YesNoQuestion['props'], answer: Answer<YesNoQuestion> | undefined, onChange): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <YesNoInput {...children} {...question} answer={answer} onChange={onChange as (newAnswer: YesNoAnswer) => void} /> : null;
  },
  renderObject(children, tags: Tags, question: ObjectQuestion['props'], answer: Answer<ObjectQuestion> | undefined): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return (
      render ? <div>
        {Object.entries(children).filter(([name, child]) => !!child).map(([name, child], index) => (
          <div key={index}>
            {cloneElement(child as any, { name, answer: answer ? answer[name] : undefined })}
          </div>
        ))}
      </div> : null
    );
  },
  renderImages(tags: Tags, question: ImagesQuestion['props'], answer: Answer<ImagesQuestion> | undefined, onChange): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <ImagesInput {...question} answer={answer} onChange={onChange as (newAnswer: ImagesAnswer) => void} /> : null;
  },
});

export const renderFormAnswer = provideFormRenderer({
  renderDate(tags: Tags, question: DateQuestion['props'], answer: Answer<DateQuestion> | undefined): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <DateTextAnswer {...question} answer={answer} /> : null;
  },
  renderText(tags: Tags, question: TextQuestion['props'], answer: Answer<TextQuestion> | undefined): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <TextInputAnswer {...question} answer={answer} /> : null;
  },
  renderYesNo(children, tags: Tags, question: YesNoQuestion['props'], answer: Answer<YesNoQuestion> | undefined): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <YesNoTextAnswer {...children} {...question} answer={answer} /> : null;
  },
  renderObject(children, tags: Tags, question: ObjectQuestion['props'], answer: Answer<ObjectQuestion> | undefined): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return (
      render ? <div>
        {Object.entries(children).filter(([name, child]) => !!child).map(([name, child], index) => (
          <div key={index}>
            {cloneElement(child as any, { name, answer: answer ? answer[name] : undefined })}
          </div>
        ))}
      </div> : null
    );
  },
  renderImages<Q extends ImagesQuestion>(tags: Tags, question: Q["props"], answer?: Answer<Q>, onChange?: (newAnswer: Answer<Q>) => void): ReactNode {
    // TODO
    return <span>TODO Images</span>;
  },
});

const provideFormStatusRenderer: FormRendererProvider<boolean> = (spec) => (tags, question, answer): boolean => {
  if (question) {
    switch (question.type) {
      case 'Object': {
        const children = Object.entries(question.props.fields).reduce((res, curr) => {
          const [name, child] = curr;
          return {
            ...res,
            [name]: provideFormStatusRenderer(spec)(
              tags,
              child,
              answer ? (answer as ObjectAnswer<ObjectQuestion>)[name] : undefined,
            ),
          };
        }, {});
        return spec.renderObject(children, tags, question.props, answer as ObjectAnswer<ObjectQuestion>);
      }
      case 'Text':
        return spec.renderText(tags, question['props'], answer as TextAnswer);
      case 'YesNo':
        const children = {
          child: question["props"].ifYes && (answer === true || (answer as YesNoAnswerWithChildren)?.value === true) ?
            provideFormStatusRenderer(spec)(
              tags,
              question["props"].ifYes,
              answer ? (answer as YesNoAnswerWithChildren).ifYes : undefined,
            ) : question["props"].ifNo && (answer === false || (answer as YesNoAnswerWithChildren)?.value === false) ?
              provideFormStatusRenderer(spec)(
                tags,
                question["props"].ifNo,
                answer ? (answer as YesNoAnswerWithChildren).ifNo : undefined,
              ) : null,
        }
        return spec.renderYesNo(children, tags, question['props'], answer as YesNoAnswer);
      case 'Date':
        return spec.renderDate(tags, question['props'], answer as DateAnswer);
      case 'Images':
        return spec.renderImages(tags, question['props'], answer as ImagesAnswer);
      default:
        return false;
    }
  }
  return false;
};

export const isPass = provideFormStatusRenderer({
  renderDate(tags, question: DateQuestion['props'], answer: Answer<DateQuestion> | undefined): boolean {
    return true;
  },
  renderText(tagsTags, question: TextQuestion['props'], answer: Answer<TextQuestion> | undefined): boolean {
    return true;
  },
  renderYesNo(children, tags, question: YesNoQuestion['props'], answer: Answer<YesNoQuestion> | undefined): boolean {
    if (answer === undefined) return true;
    if (isBoolean(answer) && isBoolean(question.isClear)) {
      return answer === question.isClear;
    } else if (isBoolean((answer as YesNoAnswerWithChildren)?.value) && isBoolean(question.isClear)) {
      return (answer as YesNoAnswerWithChildren)?.value === question.isClear;
    } else {
      return true;
    }
  },
  renderObject(children, tags, question: ObjectQuestion['props'], answer: Answer<ObjectQuestion> | undefined): boolean {
    return Object.entries(children).reduce((res: boolean, e: [string, boolean]) => {
      const [, child] = e;
      return child && res;
    }, true);
  },
  renderImages(): boolean {
    return true;
  },
});

export const isCompleted = provideFormStatusRenderer({
  renderDate(tags, question: DateQuestion["props"], answer: Answer<DateQuestion> | undefined): boolean {
    return question?.optional ? true : isValid(parse(answer as string, 'MM/dd/yyyy', new Date()));
  },
  renderText(tags, question: TextQuestion["props"], answer: Answer<TextQuestion> | undefined): boolean {
    return question?.optional ? true : answer ? answer?.trim() !== '' : false;
  },
  renderYesNo(children, tags, question: YesNoQuestion["props"], answer: Answer<YesNoQuestion> | undefined): boolean {
    if (question?.optional) {
      return true;
    } else {
      const include = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
      if (include) {
        if (isBoolean(answer)) {
          return true;
        } else {
          if (isBoolean((answer as YesNoAnswerWithChildren)?.value)) {
            return (answer as YesNoAnswerWithChildren).value ? question?.ifYes ? !!children.child : true : question?.ifNo ? !!children.child : true;
          } else {
            return false;
          }
        }
      } else {
        return true;
      }
    }
  },
  renderObject(children, tags, question: ObjectQuestion["props"], answer: Answer<ObjectQuestion> | undefined): boolean {
    if (question?.optional) {
      return true;
    } else {
      const answerKeys = children ? Object.keys(children) : [];
      return Object.keys(question.fields).every(e => answerKeys.includes(e)) && Object.values(children).every(e => e);
    }
  },
  renderImages<Q extends ImagesQuestion>(tags: Tags, question: Q["props"], answer?: ImagesAnswer): boolean {
    return question?.optional ? true : (answer?.length ?? 0) > 0;
  },
});
