import React, { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { RouteComponentProps } from 'react-router-dom';
import queryString, { ParsedQuery } from 'query-string';
import useOrganization from '../hooks/useOrganization';
import NotFound from './NotFound';
import useSaveSubmission from '../hooks/useSaveSubmission';
import { isCompleted, isPass, renderEditableFormUI, Tags } from '../form/Form';
import { Question } from '../types/Question';
import { Answer } from '../types/Answer';
import expandQueryParams from '../util/expandQueryParams';
import { splitOnce } from '../util/splitOnce';
import { createStyles, makeStyles } from '@mui/styles';
import SubmissionFormContext from '../contexts/SubmissionFormContext';
import useFetchSubmission from '../hooks/useFetchSubmission';
import { Submission as SubmissionType } from '../types/Submission';
import FetchedData from '../types/FetchedData';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      position: 'sticky',
      bottom: 0,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
    },
  }),
);

interface SubmissionParams {
  organizationId: string;
  submissionId?: string;
}

interface SubmissionProps extends RouteComponentProps<SubmissionParams> {
  queryParamOverrides?: ParsedQuery;
  submission: FetchedData<SubmissionType> | null;
}

const Submission: FC<SubmissionProps> = ({ location, match, queryParamOverrides, submission}) => {
  const theme = useTheme();
  const classes = useStyles();
  const queryParams = useMemo(() => Object.assign(expandQueryParams(queryString.parse(location.search)), queryParamOverrides || {}), [location.search, queryParamOverrides]);

  const organizationId = match.params.organizationId;
  const submissionId = match.params.submissionId;
  const patientName = queryParams?.name;
  const category = queryParams?.category;

  const organization = useOrganization(organizationId);
  const saveSubmission = useSaveSubmission(organizationId, submissionId, queryParams);

  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [complete, setComplete] = useState<boolean>(false);

  const [firstName, lastName] = patientName ? splitOnce(patientName as string, /\s+/, ' ') : [null, null];
  const [answer, setAnswer] = useState<Answer<Question> | undefined>(
    firstName && lastName ? Object.assign({ name: firstName, lastName }, submission?.data?.answer) : submission?.data?.answer || undefined,
  );

  useEffect(() => {
    submission?.data?.answer && setAnswer(
      (prev: Answer<Question> | undefined) => prev ? ({...prev, ...submission?.data?.answer}) : submission?.data?.answer
    );
  }, [submission]);

  if (!organization) {
    return <NotFound />;
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isCompleted({ category } as Tags, organization.question, answer)) {
      return;
    }

    try {
      setBusy(true);
      await saveSubmission(answer);
      setError(undefined);
      setComplete(true);
    } catch (e) {
      setError('Unexpected error occurred. Please try again later.');
      console.error('error', e);
    } finally {
      setBusy(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container maxWidth='sm' style={{ paddingTop: theme.spacing(4) }}>
        <Stack>
          <Typography color='textSecondary' variant='subtitle1' gutterBottom>
            {organization.name}
          </Typography>
          <Typography component='h1' variant='h5'>Covid Screening Form</Typography>
          <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2), width: theme.spacing(8) }} />
          {complete ? (
            isPass({ category } as Tags, organization.question, answer) ?
              (<Alert severity='success'>Pass! Thanks for filling out the form.</Alert>)
              : (<Alert severity='warning'>Please contact Supervisor or Frontdesk!</Alert>)
          ) : (
            <>
              {error && <Alert severity='error'>{error}</Alert>}
              <Stack alignItems='start'>
                <SubmissionFormContext.Provider value={{ organizationId, submissionId, queryParams }}>
                  {renderEditableFormUI({ category } as Tags, organization.question, answer, setAnswer)}
                </SubmissionFormContext.Provider>
              </Stack>
            </>
          )}
        </Stack>
      </Container>
      {!complete && (<Box className={classes.submitButton}>
        <Button type='submit' variant='contained' size='large' fullWidth
                disabled={busy || !isCompleted({ category } as Tags, organization.question, answer)}>
          <Box display='grid' alignItems='center' alignContent='center'>
            <Box gridRow='1 / span 1' gridColumn='1 / span 1' visibility={busy ? 'visible' : 'hidden'}>
              <CircularProgress variant='indeterminate' size={theme.typography.button.fontSize} thickness={4} />
            </Box>
            <Box gridRow='1 / span 1' gridColumn='1 / span 1' visibility={busy ? 'hidden' : 'visible'}>
              Submit
            </Box>
          </Box>
        </Button>
      </Box>)}
    </form>
  );
};

export const GenericSubmission: FC<RouteComponentProps<{ organizationId: string }>> = ({
  history,
  location,
  match,
}) => {
  const organizationId = match.params.organizationId.substr(0, 3);

  return (
    <Submission
      history={history}
      location={location}
      match={{ ...match, params: { organizationId } }}
      submission={null}
    />
  );
};

export const SpecificSubmission: FC<RouteComponentProps<{ organizationId: string, submissionId: string }>> = ({
  history,
  location,
  match,
}) => {
  const organizationId = match.params.organizationId.substr(0, 3);
  const submissionId = match.params.submissionId.substr(0, 5);
  const result = useFetchSubmission(organizationId, submissionId);
  return (
    <Submission
      history={history}
      location={location}
      match={{ ...match, params: { organizationId, submissionId } }}
      submission={result}
    />
  );
};
