import React, {Suspense, useMemo} from 'react';
import { Route, Switch } from 'react-router-dom';
import { GenericSubmission, SpecificSubmission } from './pages/Submission';
import NotFound from './pages/NotFound';
import { Box, createTheme, CssBaseline, LinearProgress, ThemeProvider, Typography } from '@mui/material';
import { themeOptions } from './theme';
import ConfigContext, {defaultConfig} from "./contexts/ConfigContext";

function App() {
  const config = useMemo(() => ({
    apiUrl: process.env.REACT_APP_API_URL || defaultConfig.apiUrl,
  }), []);

  return (
    <ConfigContext.Provider value={config}>
      <ThemeProvider theme={createTheme(themeOptions)}>
        <CssBaseline/>
        <Suspense fallback={
          <Box width="100%" height="100vh" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant="h5" gutterBottom>Loading questionnaire</Typography>
            <Box width={200} m={2}>
              <LinearProgress aria-label="Questionnaire" />
            </Box>
          </Box>
        }>
          <Switch>
            <Route exact path="/:organizationId([a-zA-Z0-9]{3})" component={GenericSubmission} />
            <Route exact path="/:organizationId([a-zA-Z0-9]{3})/:submissionId([a-zA-Z0-9]{5})" component={SpecificSubmission} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </ThemeProvider>
    </ConfigContext.Provider>
  );
}

export default App;
