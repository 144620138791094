import { ThemeOptions } from "@mui/material/styles";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#00A7F7',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#000C3F',
      paper: '#1a2553',
    },
  },
  typography: {
    fontFamily: 'Rubik',
  },
};
