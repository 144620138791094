import useConfig from "./useConfig";
import queryString, {ParsedQuery} from "query-string";

function defined(value?: any): boolean {
  return value !== undefined;
}

export default function usePreSignImageUpload(organizationId: string, submissionId?: string, queryParams?: ParsedQuery) {
  const { apiUrl } = useConfig();
  return async (fileName: string, contentType: string) => {
    const search = queryString.stringify({ ...(queryParams ?? {}), fileName, contentType });
    const url = [[apiUrl, 'covid-screening', organizationId, 'pre-sign-image-upload'].filter(defined).join('/'), search].filter(defined).join('?')
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    });

    if (!result.ok) {
      console.warn('Failed pre sign image upload', result);
    }

    return result;
  };
}
