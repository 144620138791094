import React from 'react';
import { default as TextAnswerComponent } from './TextAnswer';
import { TextAnswer } from '../../types/Answer';

interface Props {
  label: string;
  answer?: TextAnswer;
}

const TextInputAnswer = ({ label, answer }: Props) => (<TextAnswerComponent question={label} answer={answer} />);

export default TextInputAnswer;
