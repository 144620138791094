import usePromise from 'react-promise-suspense';
import Organization from '../types/Organization';
import useConfig from './useConfig';

async function loadOrganization(apiUrl: string, organizationId: string) {
  try {
    const result = await fetch(`${apiUrl}/covid-screening/${organizationId}`);

    if (result.ok) {
      return await result.json();
    } else {
      console.warn('Unable to load organization', result);
      return null;
    }
  } catch (e) {
    console.warn('Failed to load organization', e);
    return null;
  }
}

export default function useOrganization(organizationId: string): Organization | null {
  const { apiUrl } = useConfig();
  return usePromise(loadOrganization, [apiUrl, organizationId]) as Organization | null;
}
