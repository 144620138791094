import { Box } from '@mui/material';
import React from 'react';

interface Props {
  question: string;
  answer: string | undefined;
}

const TextAnswer = ({ question, answer }: Props) => (
  <Box display='flex' my={1} flexDirection='column'>
    <Box my={1}>{question}</Box>
    {answer && <Box style={{ borderBottom: '0.1rem solid black', width: '100%' }}>{answer}</Box>}
    {!answer &&
    <Box style={{ borderBottom: '0.1rem solid black', color: 'transparent', width: '100%' }}>{'not answered'}</Box>}
  </Box>
);


export default TextAnswer;
