import useConfig from './useConfig';
import { useEffect, useState } from 'react';
import { Submission } from '../types/Submission';
import FetchedData from '../types/FetchedData';

function defined(value?: any): boolean {
  return value !== undefined;
}

const defaultData: FetchedData<Submission> = {
  loading: true,
  error: null,
  data: null,
};

export default function useFetchSubmission(organizationId: string, submissionId?: string) {
  const { apiUrl } = useConfig();
  const [data, setData] = useState<FetchedData<Submission>>(defaultData);

  useEffect(() => {
    const url = [apiUrl, 'covid-screening', organizationId, submissionId].filter(defined).join('/');
    const handle = async () => {
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });

        if (response.ok) {
          const result = await response.json();
          setData({
            loading: false,
            data: result?.submission,
            error: null,
          });
        }

        if (!response.ok) {
          setData({
            loading: false,
            data: null,
            error: response,
          });
          console.warn('Failed to fetch submission', response);
        }
      } catch (e: any) {
        setData({
          loading: false,
          data: null,
          error: e,
        });
        console.warn('Failed to fetch submission', e);
      }
    }

    handle().then(() => {});
  }, [apiUrl, organizationId, submissionId]);

  return data;
}
