import React, { ChangeEvent, useContext, useRef, useState } from 'react';
import { Box, CircularProgress, IconButton, ImageList, ImageListItem, Theme } from '@mui/material';
import { ImagesAnswer } from '../../types/Answer';
import { ImagesQuestion } from '../../types/Question';
import { AddAPhoto } from '@mui/icons-material';
import randomId from '../../util/randomId';
import usePreSignImageUpload from '../../hooks/usePreSignImageUpload';
import SubmissionFormContext from '../../contexts/SubmissionFormContext';
import usePreSignImageRead from '../../hooks/usePreSignImageRead';
import { createStyles, makeStyles } from '@mui/styles';

type ImagesInputProps = ImagesQuestion["props"] & {
  name?: string;
  answer?: ImagesAnswer;
  onChange?: (newAnswer: ImagesAnswer) => void;
}

function randomizeFileName(fileName: string) {
  const [extension, ...rest] = fileName.split('.').reverse();

  return rest.reverse().join('.') + '_' + randomId(3) + '.' + extension;
}

function convertDataURItoBlob(dataURI: string) {
  let byteString;
  let mimeString;
  let ia;

  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = encodeURI(dataURI.split(',')[1]);
  }
  // split the mime component
  mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes to a typed array
  ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

const ImagesInput = ({ name, label, answer, onChange }: ImagesInputProps) => {
  const classes = useStyles();
  const [busy, setBusy] = useState(false);

  const { organizationId, submissionId, queryParams } = useContext(SubmissionFormContext);

  const preSignImageUpload = usePreSignImageUpload(organizationId, submissionId, queryParams);

  const uploadFileToPreSignedUrl = async (url: string, data: string | ArrayBuffer | null, contentType: string, name: string) => {
    try {
      const headers = new Headers();
      headers.append('pragma', 'no-cache');
      headers.append('Content-Type', contentType);
      if (name && name.indexOf('.pdf') < 0) {
        headers.append('Content-Disposition', 'attachment; filename=' + name);
      }

      const response = await fetch(url, {
        method: 'PUT',
        body: typeof data === 'string' || data instanceof String ? convertDataURItoBlob(data as string) : data,
        headers,
      });

      if (response.status === 200) {
        console.log('File uploaded to S3!');

        return response;
      } else {
        console.warn('Error uploading file', response);
      }
    } catch (e) {
      console.error('Uploading error', e);
    }
  };

  const processUpload = async (data: string | ArrayBuffer, fileName: string, contentType: string) => {
    try {
      const newFileName = randomizeFileName(fileName);

      const preSignResult = await preSignImageUpload(newFileName, contentType);

      if (!preSignResult.ok) {
        console.error('Failed to pre sign image upload', preSignResult);
        return;
      }

      const { preSignedUrl } = await preSignResult.json();

      if (!Boolean(preSignedUrl)) {
        console.error('PreSign URL invalid');
        return;
      }

      await uploadFileToPreSignedUrl(preSignedUrl, data, contentType, fileName);

      return newFileName;
    } catch (e) {
      console.error('Error while processing upload', e);
    }
  };

  const uploadFile = (file: File) => new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = async (e: ProgressEvent<FileReader>) => {
        const data = e.target?.result;

        if (data === null || data === undefined) {
          reject('Couldn’t load selected file.');
          return;
        }

        try {
          resolve(await processUpload(data, file.name, file.type));
        } catch (e) {
          reject(e);
        }
      };

      reader.readAsDataURL(file);
    } catch(e) {
      reject(e);
    }
  });

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    setBusy(true);

    try {
      const results = await Promise.all(Array.from(e.target.files).map(uploadFile))

      if (onChange) {
        onChange([...(answer ?? []), ...(results.filter(result => Boolean(result)) as string[])]);
      }
    } finally {
      setBusy(false);
    }
  };

  const preSignedUrls = usePreSignImageRead(organizationId, submissionId, { ...queryParams, fileName: answer ?? [] });

  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <Box my={2}>
      <div className={classes.label}>{label}</div>
      <ImageList cols={2} rowHeight={166} className={classes.list}>
        {answer?.map((fileName, index) => {
          const preSignedUrl = preSignedUrls[fileName];

          return (
            <ImageListItem key={fileName}>
              {preSignedUrl ? (
                <img
                  src={preSignedUrl}
                  alt={`Upload #${index + 1}`}
                  loading="lazy"
                />
              ) : (
                <CircularProgress
                  variant='indeterminate'
                  thickness={4}
                />
              )}
            </ImageListItem>
          );
        })}
        <input
          ref={inputRef}
          accept="image/*"
          style={{ display: 'none' }}
          id="icon-button-file"
          multiple
          type="file"
          name={name}
          onChange={handleInputChange}
          onClick={e => e.stopPropagation()}
        />
        <ImageListItem className={classes.uploadButton} onClick={() => inputRef?.current?.click()}>
          {busy ? (
            <Box>
              <CircularProgress
                variant='indeterminate'
                thickness={4}
              />
            </Box>
          ) : (
            <Box className={classes.buttonContent}>
              <IconButton color="primary" aria-label={label} component="span">
                <AddAPhoto />
              </IconButton>
              <div className={classes.label}>Add Image</div>
            </Box>
          )}
        </ImageListItem>
      </ImageList>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    label: {
      color: 'rgba(255, 255, 255, 0.7)',
      fontFamily: 'Rubik',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.4375em',
    },
    uploadButton: {
      border: `1px dashed ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      cursor: 'pointer',
    },
    buttonContent: {},
  }),
);

export default ImagesInput;
