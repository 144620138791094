const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const charactersLength = characters.length;

export default function randomId(length: number) {
  const actualLength = Math.max(0, Math.floor(length));

  let result = '';

  for (let i = 0; i < actualLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
