import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import reportWebVitals from './reportWebVitals';
import App from './App';

import ScrollRestoration from 'react-scroll-restoration';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

if (process.env.NODE_ENV === 'production') {
  const environments: { [key: string]: [string, number] } = {
    'https://covid.testing.ospitek.com': ['Testing', 1.0],
    'https://covid.view1.ospitek.com': ['View1', 0.1],
    'https://covid.view.ospitek.com': ['View', 0.1],
  };

  const [environment, tracesSampleRate] = environments[process.env.PUBLIC_URL] || [];

  if (environment) {
    Sentry.init({
      dsn: 'https://2febc731047943e7ba6cc798cf331256@o1065753.ingest.sentry.io/6057917',
      environment,
      tracesSampleRate,
      integrations: [new Integrations.BrowserTracing()],
    });
  }
}

if (process.env.NODE_ENV === 'development') {
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 2000);
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <CssBaseline />
      <ScrollRestoration />
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
